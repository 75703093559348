// OSCAR HEADER
// -----------
.header {
  position:relative;
  z-index: 1;
}
//IE9 seems to like more constraints on SVG rather than just attr width and height
.logo {
  width:140px;
  height:108px;
}
.header-content {
  height:108px;
  position: relative;
  margin-bottom:20px;
  h3 {
    font-weight: 300;
    text-transform: capitalize;
    font-size: @baseFontSize;
    line-height: @baseLineHeight;
    margin:0;
    margin-left:160px;
    position: absolute;
    bottom:0;
    left:0;
  }
  .logo {
    float:left;
  }
  .btn {
    padding:4px 16px;
    span {
      font-weight: normal;
      margin-right:5px;
    }
  }
  i {
    font-size: @headingThreeSize;
    color:@blue;
    margin-right:5px;
    vertical-align: top;
  }
  i, span {
    display:inline-block;
  }
  p {
    font-weight: 300;
    float:right;
    margin-bottom:0;
    line-height: @baseFontSize;
    margin-left:20px;
  }
  > div {
    .clearfix();
    width:50%;
    position:absolute;
    bottom:0;
    right:0;
    &:first-child {
      right:auto;
      left:0;
    }
  }
  .credit-cards,
  .verisign {
    margin:0;
  }
  .credit-cards {
    margin-top:5px;
  }
}
//Checkout header
.checkout-page .header-content {
  margin-top:10px;
  p {
    margin-bottom:30px;
  }
}
// OSCAR FOOTER UNIQUE STYLES
// -----------
#faux-foot {
  height: @footer-height;
}

.footer {
  position: relative;
  clear: both;
  min-height: @footer-height;
  background: url(/static/demo/img/ui/wavecut-blue.png) 0 0 repeat @blueDark;
  color:@white;
  a {
    color:@white;
  }
}
.footer-links {
  padding:40px 0;
  margin-left:0;
  .clearfix();
  h3{
    font-size: @headingFourSize;
    color:@white;
    margin-bottom:20px;
    text-transform: capitalize;
  }
  ul {
    margin-left: 0;
  }
  li {
    list-style: none;
    > a {
      display:block;
      padding:5px 0;
    }
  }
  > li {
    float:left;
    width:20%;
  }
  strong {
    display:block;
    margin-top:10px;
  }
}
.site-by {
  float:right;
}
.social-icons {
  font-size:@headingTwoSize;
  a {
    &:hover {
      text-decoration: none;
    } 
  }
}
.footer-disclaimer {
  font-size: @fontSizeSmall;
  line-height: @baseLineHeight * 2;
  background: #091624;
  .clearfix();
  p {
    margin:0;
    text-align: center;
  }
}
