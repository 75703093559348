// Price Color
//Price (i = child product)
.price_color {
  display:block;
  color:@green;
  font-weight:bold;
  i {
    display:block;
    color:@gray;
    font-style: normal;
  }
  del {
    color:@gray;
  }
}

//Product badges default
.product-badge {
  position:absolute;
  right:0;
  top:0;
  //Default display none
  display:none;
}

//Unavailable badge
.product-unavailable {
  .product-badge {
    display:block;
    width:113px;
    height:86px;
    background:url(/static/demo/img/ui/unavailable.png) 0 0;
  }
  img {
    .opacity(35);
  }
}

.product_pod {
  position:relative;
  height:410px;
  margin-bottom:@baseLineHeight * 2;
  overflow:hidden;
  .clearfix();
  & p {
    margin-bottom:0;
  }
  h3 {
    font-size:@headingFourSize;
    margin-bottom:0;
    line-height:@baseLineHeight;
    color:@gray;
    span {
    }
    a {
      display:block;
      text-transform: none;
      min-height:50px;
    }
  }
  .price_color {
    min-height:40px;
    margin-bottom:20px;
    position: relative;
    span {
      position: absolute;
      bottom:0;
    }
  }
  .image_container {
    border:1px solid #C7DAD7;
    a {
      display:block;
    }
  }
  .product_price {
    position:absolute;
    bottom:0;
    left:0;
    width:100%;
    // min-height:130px;
    form {
      margin-bottom:0px;
    }
  }
}

/* Product list image container */
.image_container {
  min-height:200px;
  line-height:200px;
  overflow:hidden;
  text-align: center;
  margin-bottom: @baseLineHeight / 2;
  position:relative;
  img {
    max-height:150px;
    max-width:90%;
    width:auto;
    margin:0px auto;
  }
}
//Category page title and description
.category-title {
  background:#244761;
  height:250px;
  overflow:hidden;
  position: relative;
  color:@white;
  margin-bottom:@baseLineHeight;
  h1 {
    color:@white;
    text-transform: uppercase;
    margin-bottom:0;
    font-size:@headingTwoSize;
    margin: 50px 35% 0 30px;
  }
  p {
    border-top:2px solid @white;
    padding-top:10px;
    margin: 0 35% 0 30px;
  }
  div {
    position: relative;
    background:url(/static/demo/img/ui/bg-category-title.png) 80% 0 no-repeat;
    position: absolute;
    top:0;
    left:0px;
    height:250px;
    width:85%;
  }
  img {
    position: absolute;
    width:auto;
    height:250px;
    right:0;
    top:0;
  }
}
//Aside Categories and search filters
.aside-options {
  h4 {
    background:@navbarInverseBackground;
    color:@white;
    text-transform: none;
    font-size: 1em;
    line-height: 1em;
  }
  ul {
    list-style: none;
  }
  dl, > ul {
    margin-top:0;
    border:1px solid #D8D8D8;
    background:#F2F2F2;
  }
  > ul {
    margin-left:0;
    margin-bottom:@baseLineHeight;
    ul {
      margin:0;
    }
  }
  ul a {
    display:block;
  }
  ul a:hover,
  .active a {
    background:@blue;
    color:@white;
    i {
      display: inline-block;
    }
  }
  //New title or list need separation
  dt, > ul > li {
     border-top:1px solid #D8D8D8;
     &:first-child {
      border-top:0;
     }
  }
  dt, dd, li {
    position:relative;
  }
  dt, dd, h4, ul a {
    padding:15px;
    margin:0;
  }
  //Indent child lists
  dd, ul ul a {
    padding-left:30px;
  }
  ul ul ul a {
    padding-left:45px;
  }
  ul ul ul ul a {
    padding-left:60px;
  }
  i {
    position:absolute;
    right:10px;
    top:18px;
  }
}