//
// Grid system
// --------------------------------------------------


// Fixed (940px)
#grid > .core(@gridColumnWidth, @gridGutterWidth);

// Fluid (940px)
#grid > .fluid(@fluidGridColumnWidth, @fluidGridGutterWidth);

// Reset utility classes due to specificity
[class*="span"].hide,
.row-fluid [class*="span"].hide {
  	display: none;
}

[class*="span"].pull-right,
.row-fluid [class*="span"].pull-right {
  	float: right;
}

//no margin on lists
ol,
ul {
	&.row-fluid {
		margin-left:0;
		list-style: none;
	}
}

//Remove margin if condition
.row-fluid [class*="span"] {
	&.no-margin {
		margin-left:0;
	}
}

.product-list {
	//Gutters change for lists of procuts
	@gridGutterWidth:40px;
	#grid > .fluid(@fluidGridColumnWidth, @fluidGridGutterWidth);

	//Remove margin if condition
	.row-fluid [class*="span"] {
		&.no-margin {
			margin-left:0;
		}
	}
}
