//
// Button groups
// --------------------------------------------------


// Make the div behave like a button
.btn-group {
  position: relative;
  display: inline-block;
  .ie7-inline-block();
  font-size: 0; // remove as part 1 of font-size inline-block hack
  vertical-align: middle; // match .btn alignment given font-size hack above
  white-space: nowrap; // prevent buttons from wrapping when in tight spaces (e.g., the table on the tests page)
  .ie7-restore-left-whitespace();
}

// Space out series of button groups
.btn-group + .btn-group {
  margin-left: 5px;
}

// Optional: Group multiple button groups together for a toolbar
.btn-toolbar {
  font-size: 0; // Hack to remove whitespace that results from using inline-block
  margin-top: @baseLineHeight / 2;
  margin-bottom: @baseLineHeight / 2;
  > .btn + .btn,
  > .btn-group + .btn,
  > .btn + .btn-group {
    margin-left: 5px;
  }
}

// Float them, remove border radius, then re-add to first and last elements
.btn-group > .btn {
  position: relative;
  .border-radius(0);
}
.btn-group > .btn + .btn {
  margin-left: -1px;
}
.btn-group > .btn,
.btn-group > .dropdown-menu,
.btn-group > .popover {
  //Can't use ems here as initial font size is 0 as part of part 1 hack
  font-size: @bodySize + 0px; // redeclare as part 2 of font-size inline-block hack
}

//TODO: Move this into oscar template instead
.btn-group > .dropdown-menu {
  left:auto;
  right:0;
}

// Reset fonts for other sizes
.btn-group > .btn-mini {
  font-size: @fontSizeMini;
}
.btn-group > .btn-small {
  font-size: @fontSizeSmall;
}
.btn-group > .btn-large {
  font-size: @fontSizeLarge;
}

// Set corners individual because sometimes a single button can be in a .btn-group and we need :first-child and :last-child to both match
.btn-group > .btn:first-child {
  margin-left: 0;
  .border-top-left-radius(@baseBorderRadius);
  .border-bottom-left-radius(@baseBorderRadius);
}
// Need .dropdown-toggle since :last-child doesn't apply given a .dropdown-menu immediately after it
.btn-group > .btn:last-child,
.btn-group > .dropdown-toggle {
  .border-top-right-radius(@baseBorderRadius);
  .border-bottom-right-radius(@baseBorderRadius);
}
// Reset corners for large buttons
.btn-group > .btn.large:first-child {
  margin-left: 0;
  .border-top-left-radius(@borderRadiusLarge);
  .border-bottom-left-radius(@borderRadiusLarge);
}
.btn-group > .btn.large:last-child,
.btn-group > .large.dropdown-toggle {
  .border-top-right-radius(@borderRadiusLarge);
  .border-bottom-right-radius(@borderRadiusLarge);
}

// On hover/focus/active, bring the proper btn to front
.btn-group > .btn:hover,
.btn-group > .btn:focus,
.btn-group > .btn:active,
.btn-group > .btn.active {
  z-index: 2;
}

// On active and open, don't show outline
.btn-group .dropdown-toggle:active,
.btn-group.open .dropdown-toggle {
  outline: 0;
}



// Split button dropdowns
// ----------------------

// Give the line between buttons some depth
.btn-group > .btn + .dropdown-toggle {
  padding-left: 8px;
  padding-right: 8px;
  .box-shadow(~"inset 1px 0 0 rgba(255,255,255,.125), inset 0 1px 0 rgba(255,255,255,.2), 0 1px 2px rgba(0,0,0,.05)");
  *padding-top: 5px;
  *padding-bottom: 5px;
}
.btn-group > .btn-mini + .dropdown-toggle {
  padding-left: 5px;
  padding-right: 5px;
  *padding-top: 2px;
  *padding-bottom: 2px;
}
.btn-group > .btn-small + .dropdown-toggle {
  *padding-top: 5px;
  *padding-bottom: 4px;
}
.btn-group > .btn-large + .dropdown-toggle {
  padding-left: 12px;
  padding-right: 12px;
  *padding-top: 7px;
  *padding-bottom: 7px;
}

.btn-group.open {

  // The clickable button for toggling the menu
  // Remove the gradient and set the same inset shadow as the :active state
  .dropdown-toggle {
    background-image: none;
    .box-shadow(~"inset 0 2px 4px rgba(0,0,0,.15), 0 1px 2px rgba(0,0,0,.05)");
  }

  // Keep the hover's background when dropdown is open
  .btn.dropdown-toggle {
    background-color: @btnBackgroundHighlight;
  }
  .btn-primary.dropdown-toggle {
    background-color: @btnPrimaryBackgroundHighlight;
  }
  .btn-warning.dropdown-toggle {
    background-color: @btnWarningBackgroundHighlight;
  }
  .btn-danger.dropdown-toggle {
    background-color: @btnDangerBackgroundHighlight;
  }
  .btn-success.dropdown-toggle {
    background-color: @btnSuccessBackgroundHighlight;
  }
  .btn-info.dropdown-toggle {
    background-color: @btnInfoBackgroundHighlight;
  }
  .btn-inverse.dropdown-toggle {
    background-color: @btnInverseBackgroundHighlight;
  }
}


// Reposition the caret
.btn .caret {
  margin-top: 8px;
  margin-left: 0;
}
// Carets in other button sizes
.btn-large .caret {
  margin-top: 6px;
}
.btn-large .caret {
  border-left-width:  5px;
  border-right-width: 5px;
  border-top-width:   5px;
}
.btn-mini .caret,
.btn-small .caret {
  margin-top: 8px;
}
// Upside down carets for .dropup
.dropup .btn-large .caret {
  border-bottom-width: 5px;
}



// Account for other colors
.btn-primary,
.btn-warning,
.btn-danger,
.btn-info,
.btn-success,
.btn-inverse {
  .caret {
    border-top-color: @white;
    border-bottom-color: @white;
  }
}



// Vertical button groups
// ----------------------

.btn-group-vertical {
  display: inline-block; // makes buttons only take up the width they need
  .ie7-inline-block();
}
.btn-group-vertical > .btn {
  display: block;
  float: none;
  max-width: 100%;
  .border-radius(0);
}
.btn-group-vertical > .btn + .btn {
  margin-left: 0;
  margin-top: -1px;
}
.btn-group-vertical > .btn:first-child {
  .border-radius(@baseBorderRadius @baseBorderRadius 0 0);
}
.btn-group-vertical > .btn:last-child {
  .border-radius(0 0 @baseBorderRadius @baseBorderRadius);
}
.btn-group-vertical > .btn-large:first-child {
  .border-radius(@borderRadiusLarge @borderRadiusLarge 0 0);
}
.btn-group-vertical > .btn-large:last-child {
  .border-radius(0 0 @borderRadiusLarge @borderRadiusLarge);
}
