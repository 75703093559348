// OSCAR ACCOUNTS UNIQUE STYLES
// -----------
.login_form, .register_form {
  form {
    .well();
    min-height:400px;
  }
}
.nav-profile {
  margin-bottom:0;
}
.account-pages .content,
.account-profile .tab-content {
   padding:@baseLineHeight + 10px;
   border:1px solid darken(@grayLighter, 5%);
   border-top-width:0;
}
.nav-stacked .icon-chevron-right {
  margin-top: 5px;
  margin-right: -6px;
  .opacity(50);
}
.nav-stacked .active .icon-chevron-right {
  .opacity(100);
}
//Language select
#language_selector select {
  width:100px;
}
//Same Size buttons on the account pages 
.account-profile {
  .btn-large {
    font-size:1em;
    padding: 8px 16px;
  }
}