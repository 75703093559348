//Product title and description
.product-highlight {
  h4 {
    margin-bottom:0;
    text-transform: uppercase;
  }
  .price_color {
    float:left;
    i {
      font-size:@headingFourSize;
      line-height:@headingFourSize;
    }
  }
  .free-delivery {
    float:right;
  }
}

.product-page .free-delivery {
  color:@blue;
  border:1px solid @blue;
  padding:10px;
  > span span {
    font-size: (20 / @bodySize) + 0em;
    font-weight:@headingsFontWeight;
  }
}

//Review styles
.review-title {
  margin-bottom:@baseLineHeight;
  .clearfix();
  p {
    font-size:@headingFourSize;
  }
  > a {
    float:right;
  }
}
.review {
  border-bottom:1px solid #ddd;
  padding-bottom:15px;
  margin-bottom:15px;
  .clearfix();
  h3 {
    font-size:@headingFourSize;
    line-height:@headingThreeSize;
    text-transform: none;
    a {
      display:block;
      margin-left: 0;
    }
  }
  h4 {
    border-top:1px solid @grayLighter;
    border-bottom:1px solid @grayLight;
    font-size: @baseFontSize + 1;
  }
}
.review p, .review h3, .review h4,
.review_add h3 {
  margin-bottom:15px;
}
.review_content,
.review_votes {
  div {
    padding:0 15px;
  }
}
.review_content {
  div {
    padding-left:0px;
  }
}
.review_votes {
  div {
    border-left:solid 1px #ddd;
  }
  .vote_agree {
    float:left;
    margin-right:10px;
  }
  form {
    padding-bottom:5px;
    margin-bottom:0px;
  }
}
.review_add {
  h3 {
    color:@grayOff;
    font-size: @headingFourSize;
  }
}
//Child product lists
.child-product {
  .clearfix();
  h4 {
    color:@blueDark;
  }
  p, h4 {
    margin-bottom:15px;
  }
  .price_color {
    font-size:@headingThreeSize;
  }
  .image_container {
    background:@white;
    line-height: 80px;
    min-height: 80px;
    width:80px;
    border:1px solid #ddd;
    float:left;
    img {
      max-height:70px;
      max-width:98%;
    }
  }
  > div:first-child + div  {
    margin-left:100px;
  }
}
.child-form {
  label {
    display:block;
  }
}
.form-notify {
  .btn {
    display:block;
    margin-top:5px;
  }
}
//Gallery
.product-gallery {
  .image_container {
    min-height:none;
    line-height:inherit;
    img {
      max-height:none;
      max-width:none;
    }
  }
}

//Fancy HR with anchor
.hr {
  position: relative;
  border-top:1px solid #ddd;
  margin:60px 0;  
  i {
    font-size: 30px;
    color:#ddd;
    display:block;
    position: absolute;
    left:50%;
    top:-15px;
    padding:0 15px;
    background:@white;
  }
}
