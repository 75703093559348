// Promotional block styles
.promotion-block {
  .clearfix();
}
.promotion_single {
  h2 {
    a {
      color:@navbarInverseBackground;
    }
  }
  h4 {
    margin-bottom:0;
  }
  h3, h2 {
    margin-bottom:15px;
  }
  .image_container {
    border:1px solid #ddd;
    min-height: 400px;
    line-height: 400px;
    img {
      width:90%;
      max-width: none;
      max-height:none;
      height:auto;
      display:inline-block;
    }
  }
  .single-content {
    padding:30px 20px 0;
  }
}

//If promotions in a smaller container
.span4,
.span3,
.span2,
.span1 {
  .promotion_single {
    > h3 {
      font-size:1em;
      line-height: 1em;
      text-align:left;
      background:@navbarInverseBackground;
      color:@white;
      margin:0;
      padding:15px;
    }
    .well {
      //fonts slightly smaller
      font-size:0.875em;
      background:#F2F2F2;
    }
    [class*="span"] {
      float:none;
      margin-left: 0;
      width:auto;
    }
    .single-content {
      padding: 0;
    }
    .image_container {
      background:@white;
      min-height: 200px;
      line-height: 200px;
    }
    .btn-large {
      font-size: 1em;
      padding: 8px 16px;
    }
  }
}

//TODO: what is this for?
.pro
.sidebar {
  .promotion_single {
    h2 {
      font-size:18px;
      line-height:22px;
    }
    h3 {
      font-size:14px;
      line-height:18px;
    }
    .row-fluid {
      > [class*="span"] {
        float:none;
        width:auto;
      } 
    }
  }
}

//Read more link
.read-more {
  display:block;
  text-align:right;
  margin-top:20px;
}
.product-carousel {
  .read-more {
    margin-right:5%;
  }
}

.free-delivery {
  text-transform: uppercase;
  line-height: 1.1em;
  i {
    display:inline-block;
    float:left;
    font-size: (@OneSize / @ThreeSize) + 0em;
  }
  > span {
    margin-left:(@OneSize / @ThreeSize) + 0.5em;
    display:block;
  }
  > span span {
    font-size: (@TwoSize / @ThreeSize) + 0em;
    line-height: 1em;
  }
  strong {
    font-size: (17 / @ThreeSize) + 0em;
    letter-spacing: 0;
  }
}

//Widget blocks
.widget-delivery,
.widget-shipping {
  .clearfix();
  background:@navbarInverseBackground;
  color:@white;
  padding:30px;
  h3 {
    color:@white;
  }
  .btn {
    float:right;
  }
}

.widget-image-link {
  color:@white;
  text-align: center;
  img {
    width:100%;
  }
  > a {
    display:block;
    &:hover {
      text-decoration: none;
      h3 {
        background: #3C92E4;
      }
    }
  }
  h3 {
    color:@white;
    margin:0;
    padding:10px 15px;
    background:@blueDark;
    position: relative;
    text-transform: none;
    small {
      display:block;
      line-height: @headingFourSize;
    }
  }
  i {
    position: absolute;
    right:5px;
    top:10px;
    font-size: (32 / @ThreeSize) + 0em;
  }
  &.large {
    h3 {
      background:@navbarInverseBackground;
    }
  }
}


//If promotions in a smaller container
.span3,
.span2,
.span1 {
  .widget-image-link {
    //fonts slightly smaller
    font-size:0.7em;
    i {
      top:14px;
    }
  }
}