// OSCAR CHECKOUT UNIQUE STYLES
// -----------
.basket_summary,
.later_summary {
  padding-left:0px;
  .actions {
    margin-left:0px;
  }
  .checkout-quantity {
      input {
          margin-right: 2px;
          margin-bottom:0px;
      }
  }
  .basket-products {
      border-bottom: 1px solid #E0E0E0;
      padding: 0 0 10px;
      margin-bottom: 18px;
  }
}
//Basket column names
.basket-title {
  clear:both;
  border:1px solid #ddd;
  background:#F2F2F2;
  padding:10px;
  margin-bottom:10px;
  .row-fluid [class*="span"] {
    min-height:0;
  }
  h4 {
    margin:0;
  }
}
.well {
  .basket-title {
    background:none;
    padding:0;
    border-width:0;
    border-bottom-width:1px;
  }
}
//Basket list item
.basket-items {
  border-bottom:1px solid lighten(@grayLight, 30%);
  padding:15px 0;
  [class*="span"] {
    .image_container {
      min-height:100px;
      line-height: 100px;
      border:1px solid #ddd;
      background:@white;
    }
    h3, h4 {
      margin-bottom:0;
    }
    h4 {
      color:@grayOff;
      text-transform: uppercase;
    }
    h3 {
      text-transform: none;
      font-size: 20px;
      a {
        color:@navbarInverseBackground;
      }
    }
    img {
      max-height:90px;
    }
  }
  .price_color {
    font-size:@baseFontSize + 2;
    font-weight:bold;
  }
}
//Basket Totals table
#basket_totals {
	th small {
		font-weight: normal;
	}
  h3, h4 {
    margin-bottom:0;
  }
}
//Proceed to checkout area
.checkout-proceed {
  float:right;
  text-align: right;
  margin-bottom:30px;
  span {
    display:inline-block;
    text-align: left;
    margin:0 20px;
  }
  .credit-cards {
    margin:0;
  }
  > strong {
    font-size:@headingFourSize;
    text-transform: uppercase;
    display:block;
    margin:20px 0;
  }
}
//Shipping addresses
.choose-block {
  ul {
    .remove_bullets();
    .clearfix();
    li {
        .default-address {
            background-color: @grayLighter;
      }
    }
  }
  form {
    margin-bottom:0;
  }
  .well-info.default-address {
    background-color: @successBackground;
    border-color: @successBorder;
  }
  .alert-actions {
    .clearfix();
    form,
    a {
      float:left;
      margin:0px 5px;
    }
    form {
      input {
        width:auto;
      }
    }
  } 
}
//Checkout navigation
.checkoutNav {
  .container-fluid {
    max-width: 960px;
  }
  position: relative;
  margin:30px 0;
  background:#F2F2F2;
  padding:5px 0; 
  border-top:2px solid @navbarInverseBackground;
  border-bottom:1px solid #DDD;
  i {
    display:none;
    vertical-align: bottom;
    position: absolute;
    width:100%;
    left:0;
    top:-1.1em;
  }
  ul {
    .clearfix();
    li {
      position: relative;
      list-style: none;
      display:block;
      text-align: center;
      width:@quart;
      float: left;
      margin: 0;
      font-weight: bold;
      text-transform: uppercase;
      color:@navbarInverseBackground;
      font-size: (18 / @bodySize) + 0em;
      &.visited {
        color:@green;
        i:first-child + i {
          display:inline-block;
          font-size: (@ThreeSize / 18) + 0em;
          line-height: 1em;
        }
        a {
          color:@green;
        }
      }
      &.active {
        i:first-child {
          display:inline-block;
          font-size: (@TwoSize / 18) + 0em;
          line-height: 1em;
          height:1em;
          overflow: hidden;
          top:-1.2em;
        }
      }
    }
  }
}
//Footer and Checkout images
.credit-cards,
.verisign {
  display:inline-block;
  vertical-align: middle;
  margin:0 4px;
}
.credit-cards {
  width:178px;
  height:21px;
  background: url(/static/demo/img/ui/sprite.png) 0 -49px repeat transparent;
}
.verisign {
  width:100px;
  height:49px;
  background: url(/static/demo/img/ui/sprite.png) 0 0 repeat transparent;
}

