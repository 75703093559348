@font-face {
    font-family: 'icomoon';
    src:url('/static/demo/fonts/icomoon.eot');
    src:url('/static/demo/fonts/icomoon.eot?#iefix') format('embedded-opentype'),
        url('/static/demo/fonts/icomoon.woff') format('woff'),
        url('/static/demo/fonts/icomoon.ttf') format('truetype'),
        url('/static/demo/fonts/icomoon.svg#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
}

/* Use the following CSS code if you want to use data attributes for inserting your icons */
[data-icon]:before {
    font-family: 'icomoon';
    content: attr(data-icon);
    speak: none;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
}


/* Use the following CSS code if you want to have a class per icon */
/*
Instead of a list of all class selectors,
you can use the generic selector below, but it's slower:
[class*="icon-"] {
*/
.icon-cart, .icon-star, .icon-lock, .icon-mobile, .icon-earth, .icon-arrow-right, .icon-search, .icon-truck, .icon-twitter, .icon-facebook, .icon-pinterest, .icon-mail, .icon-plus, .icon-noun_project_62, .icon-arrow-down, .icon-check-alt, .icon-x-altx-alt, .icon-checkout-half-wheel, .icon-trashcan, .icon-move, .icon-pencil, .icon-link, .icon-pictureframe, .icon-pictures, .icon-reorder {
    font-family: 'icomoon';
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
}
.icon-cart:before {
    content: "\e000";
}
.icon-star:before {
    content: "\e001";
}
.icon-lock:before {
    content: "\f0be";
}
.icon-mobile:before {
    content: "\e002";
}
.icon-earth:before {
    content: "\e003";
}
.icon-arrow-right:before {
    content: "\e004";
}
.icon-search:before {
    content: "\f0c5";
}
.icon-truck:before {
    content: "\f211";
}
.icon-twitter:before {
    content: "\e005";
}
.icon-facebook:before {
    content: "\e006";
}
.icon-pinterest:before {
    content: "\e007";
}
.icon-mail:before {
    content: "\e008";
}
.icon-plus:before {
    content: "\2b";
}
.icon-noun_project_62:before {
    content: "\21";
}
.icon-arrow-down:before {
    content: "\22";
}
.icon-check-alt:before {
    content: "\23";
}
.icon-x-altx-alt:before {
    content: "\24";
}
.icon-checkout-half-wheel:before {
    content: "\25";
}
.icon-trashcan:before {
    content: "\26";
}
.icon-move:before {
    content: "\f322";
}
.icon-pencil:before {
    content: "\f040";
}
.icon-link:before {
    content: "\f022";
}
.icon-pictureframe:before {
    content: "\f41e";
}
.icon-pictures:before {
    content: "\e009";
}
.icon-reorder:before {
    content: "\f0c9";
}
