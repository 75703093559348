/* Modal modifications */
.large-content {
  height:300px;
  overflow:auto;
  padding-right:30px;
}
.sub-header {
  h2 {
    small {
     .clearfix(); 
    }
    a {
      float:right;
      line-height: @baseLineHeight * 2;
    }
  }
}
/* Star Rating Default */
.star-rating {
    letter-spacing: -1px;
    color:#CBCBCB;
    a {
        letter-spacing: 0;
        margin-left:10px;
    }
}
.star-rating.One {
    i:first-child {
        color:#E6CE31;
    }
}
.star-rating.Two {
    i:first-child, i:first-child + i {
        color:#E6CE31;
    }
}
.star-rating.Three {
    i:first-child, i:first-child + i, i:first-child + i + i {
        color:#E6CE31;
    }
}
.star-rating.Four {
    i:first-child, i:first-child + i, i:first-child + i + i, i:first-child + i + i + i {
        color:#E6CE31;
    }
}
.star-rating.Five {
    i {
        color:#E6CE31;
    }
}

//Flexslider Overrides
#homepage-carousel, 
#product-image-carousel {
    .flex-direction-nav a { 
        width: 67px; 
        height: 67px; 
        background: url(/static/demo/js/flexslider/homepage-nav.png) no-repeat 0 0; 
        top: 45%;
    }
    .flex-direction-nav .flex-next {
        background-position: 100% 0; 
        right: 20px; 
    }
    .flex-direction-nav .flex-prev {
        left: 20px;
    }
}
//Homepage carousel
#homepage-carousel {
    &.flexslider {
        overflow: hidden;
        margin-bottom:30px;
    }
    .flex-control-nav { 
        bottom: 30px; 
    }
}
//Image gallery product page carousel
#product-image-carousel {
    li {
        line-height:600px;
        text-align:center;
    }
    img {
        vertical-align: middle;
        max-height:600px;
        max-width:100%;
        width:auto;
        margin:0 auto;
        display:inline-block;
    }
}
.product-page .product-carousel {
    .flexslider {
        margin-bottom:0;
    }
}
//Carousel single,list containers
.product-carousel, 
.product-single-carousel,
.promotion_single {
    .well {
        background:@white;
    }
    > h3 {
        text-align: center;
    }
}
.product-carousel {
    .flexslider {
        padding:0 5%;
    }
}
.product-single-carousel {
    .flexslider {
        padding:0 10%;
    }
}
//Product list carousel
.product-carousel {
    .product_pod {
        height:410px;
    }
    .flexslider {
        .slides {
            img {
                width:auto;
                display:inline-block;
            }
        }
        .flex-direction-nav .flex-next {
            right: 0; 
        }
        .flex-direction-nav .flex-prev {
            left: 0;
        }
    }
    .product_pod {
        padding:0 5%;
        .product_price {
            left: 5%;
            width: 90%;
            bottom:0;
        }
    }
}
//Single product carousel
.product-single-carousel {
  .clearfix();
  .image_container {
    border:1px solid #ddd;
  }
  > h3 {
    border-bottom:1px solid #ddd;
    padding-bottom:15px;
    margin-bottom:30px;
  }
}