//
// Utility classes
// --------------------------------------------------


// Quick floats
.pull-right {
  float: right;
}
.pull-left {
  float: left;
}
.pull-clear {
  clear: both;
}

// Toggling content
.hide {
  display: none;
}
.show {
  display: block;
}

// Visibility
.invisible {
  visibility: hidden;
}

// For Affix plugin
.affix {
  position: fixed;
}

.align-right,
.table .align-right,
.table .align-right {
  text-align:right;
}

.align-center,
.table .align-center,
.table .align-center {
  text-align:center;
}
